import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import 'styles/pages/loginPage.scss'
import { InputField, PasswordInputField } from 'components/generic'
import { adminController } from 'controllers'
import { adminInterface } from 'interfaces'
import { errorHelper } from 'helpers'

interface LoginPageProps {
  isLoggedIn: boolean
  setIsLoggedIn: (state: boolean) => void
}

function LoginPage({ isLoggedIn, setIsLoggedIn }: LoginPageProps) {
  const navigate = useNavigate()
  const [loginData, setLoginData] = useState<adminInterface.IAdminLogin>({
    email: '',
    password: '',
  })
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  // Handle redirect if already logged in
  useEffect(() => {
    if (isLoggedIn || sessionStorage.getItem('auth_state') === 'logged_in') {
      navigate('/')
    }
  }, [isLoggedIn])

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setLoginData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }, [])

  const handleLogin = useCallback(async () => {
    if (isLoggingIn) {
      return
    }

    setIsLoggingIn(true)

    try {
      if (!loginData.email || !loginData.password) {
        throw new errorHelper.CodeError('Please enter email and password', 400)
      }

      const response = await adminController.login(loginData)

      if (!response || !response.accessToken) {
        throw new errorHelper.CodeError('Invalid login response', 401)
      }

      setIsLoggedIn(true)
      enqueueSnackbar('Logged in successfully', { variant: 'success' })
      navigate('/')
    } catch (error: any) {
      const errorMessage = error.message || 'Login failed'
      enqueueSnackbar(errorMessage, { variant: 'error' })
      setIsLoggedIn(false)
    } finally {
      setIsLoggingIn(false)
    }
  }, [loginData, isLoggingIn, setIsLoggedIn, navigate, enqueueSnackbar])

  return (
    <div className='loginPage'>
      <div className='loginContent'>
        <div className='header'>
          <h2 className='welcomeText'>Hello again!</h2>
          <p className='subtitle'>Please enter your details to sign in</p>
        </div>
        <form
          className='loginForm'
          onSubmit={(e) => {
            e.preventDefault()
            handleLogin()
          }}
        >
          <div className='formFields'>
            <div className='field'>
              <label>Email/Username</label>
              <div className='inputContainer'>
                <input
                  type='email'
                  name='email'
                  value={loginData?.email}
                  onChange={handleChange}
                  placeholder='Enter your email'
                  disabled={isLoggingIn}
                />
              </div>
            </div>
            <div className='field'>
              <label>Password</label>
              <div className='inputContainer'>
                <input
                  type='password'
                  name='password'
                  value={loginData?.password}
                  onChange={handleChange}
                  placeholder='Enter your password'
                  disabled={isLoggingIn}
                />
                <span className='passwordToggle'>
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M2.5 10C2.5 10 5 5 10 5C15 5 17.5 10 17.5 10C17.5 10 15 15 10 15C5 15 2.5 10 2.5 10Z'
                      stroke='currentColor'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12Z'
                      stroke='currentColor'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <a href='/forgot-password' className='forgotPassword'>
            Forgot your password?
          </a>
          <button type='submit' className='loginButton' disabled={isLoggingIn}>
            {isLoggingIn ? 'Signing In...' : 'Sign In'}
          </button>
          <div className='createAccount'>
            <span>Don&apos;t have an account?</span>
            <a href='/register'>Create an account</a>
          </div>
        </form>
      </div>
    </div>
  )
}

export default LoginPage
