import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import 'styles/pages/allAppsPage.scss'
import { appController } from 'controllers'
import { appInterface } from 'interfaces'
import { AppList } from 'components/apps'
import { useSnackbar } from 'notistack'

function AllAppsPage({ isLoggedIn, pending }: { isLoggedIn: boolean; pending?: boolean }) {
  const [search, setSearch] = useState('')
  const [apps, setApps] = useState<appInterface.IApp[]>([])
  const [count, setCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [page, setPage] = useState<number>(0)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoggedIn) navigate('/login')
  }, [isLoggedIn, navigate])

  async function getAllApps() {
    try {
      const appsFetch: { apps: appInterface.IApp[]; count: number } =
        await appController.getAllApps({
          page,
          filter: pending ? { status: 'Pending' } : undefined,
        })
      if (!appsFetch || !appsFetch.apps) {
        throw new Error('Invalid response format from server')
      }
      setApps(apps.concat(appsFetch.apps))
      setCount(appsFetch.count)
      setPage(page + 1)
    } catch (error: any) {
      console.error('Error fetching apps:', error)
      enqueueSnackbar(error.response?.data || error.message || 'Failed to fetch apps', {
        variant: 'error',
        autoHideDuration: 3000,
      })
      setApps([])
    }
    setIsLoading(false)
  }

  async function findApps() {
    try {
      const appsFetch = await appController.getManyAppsByName({
        name: search,
        filter: pending ? { status: 'Pending' } : undefined,
      })
      if (!appsFetch) {
        throw new Error('No apps found')
      }
      setApps(appsFetch)
    } catch (error: any) {
      console.error('Error searching apps:', error)
      enqueueSnackbar(error.response?.data || error.message || 'Failed to search apps', {
        variant: 'error',
        autoHideDuration: 3000,
      })
      setApps([])
    }
  }

  useEffect(() => {
    if (search.length > 2) {
      findApps()
    } else if (search.length === 0) {
      setPage(0)
      setApps([])
      getAllApps()
    }
  }, [search, pending])

  return isLoading ? (
    <div className='allAppsPage' role='status' aria-busy='true'>
      <div className='skeleton' style={{ width: '60%' }} />
      <div className='skeleton' style={{ width: '40%' }} />
      <div className='skeleton' style={{ width: '80%' }} />
      <div className='skeleton' style={{ width: '70%' }} />
    </div>
  ) : (
    <div className='allAppsPage'>
      <h1 className='title'>{pending ? 'Pending Apps' : 'All Apps'}</h1>
      <div className='searchWrapper'>
        <input
          className='searchField'
          value={search}
          onChange={(v) => setSearch(v.target.value)}
          placeholder='Search apps...'
          aria-label='Search apps'
          role='searchbox'
        />
        {search && (
          <button className='clearSearch' onClick={() => setSearch('')} aria-label='Clear search'>
            <svg
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M15 5L5 15M5 5L15 15'
                stroke='currentColor'
                strokeWidth='1.67'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </button>
        )}
      </div>
      <main className='appBox'>
        <AppList apps={apps} />
      </main>
      {search.length === 0 && apps.length < count && (
        <button
          type='button'
          onClick={() => getAllApps()}
          className='cta'
          aria-label='Load more apps'
          disabled={isLoading}
        >
          {isLoading ? 'Loading...' : 'Load More'}
        </button>
      )}
    </div>
  )
}

export default AllAppsPage
