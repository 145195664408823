import { appInterface, pageInterface, appSuggestionInterface } from 'interfaces'
import { errorHelper } from 'helpers'
import { getAuthInstance, getInstance } from 'utils/axiosInstance'
import { IPlatforms } from 'interfaces/appInterface'

const backendURL = process.env.REACT_APP_BACKEND_URL
const itunesInstance = getInstance(`${backendURL}/itunes`)
const appInstance = getInstance(`${backendURL}/apps`)
const appInstanceAuth = getAuthInstance(`${backendURL}/apps`)
const adminInstance = getAuthInstance(`${backendURL}/admin`)

export async function createApp(appData: appInterface.INewApp) {
  try {
    await appInstanceAuth.post('/admin', JSON.stringify({ appData }))
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function changeAppStatus({
  uuid,
  status,
}: {
  uuid: string
  status: appInterface.Status
}) {
  try {
    const apps = await appInstanceAuth.post(`/status/${status}`, JSON.stringify({ uuid }))
    return apps.data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function getAppById(uuid: string) {
  try {
    const app = await appInstance.get(`/id/${uuid}`)
    return app.data.app
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function getAllApps({
  page,
  filter,
}: {
  page: number
  filter?: Record<string, unknown>
}) {
  try {
    const params: Record<string, any> = { page }
    if (filter?.status) {
      params.filter = JSON.stringify({ status: filter.status })
    }
    const response = await appInstanceAuth.get('/all', { params })

    // Validate response data structure
    if (!response.data || !Array.isArray(response.data.apps)) {
      throw new Error('Invalid response format from server')
    }

    return response.data
  } catch (error: any) {
    throw new errorHelper.CodeError(
      error.response?.data || error.message || 'Failed to fetch apps',
      error.response?.status || 500,
    )
  }
}

export async function getManyAppsById(uuidList: string[], limit?: number) {
  try {
    const apps = await appInstance.get('/search/id', { params: { uuidList, limit } })
    return apps.data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function getManyAppsByName({
  name,
  filter,
}: {
  name: string
  filter?: Record<string, unknown>
}) {
  try {
    const apps = await appInstanceAuth.get('/search/name/admin', { params: { name, filter } })
    return apps.data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function getMergedAppsByName(name: string) {
  try {
    const apps = await appInstance.get('/search/name/merged', { params: { name } })
    return apps.data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function getApps() {
  try {
    const apps = await appInstance.get('/')
    return apps.data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function deleteApp(uuid: string) {
  try {
    await appInstanceAuth.delete('/', { data: JSON.stringify({ uuid }) })
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function searchiTunesByName(name: string) {
  try {
    const suggestions = await itunesInstance.get('/', { params: { name } })
    return suggestions.data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function saveItunesAppById(itunesId: number) {
  try {
    return await itunesInstance.post('/', JSON.stringify({ itunesId }))
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function updatePlatformRequest({
  appId,
  requestId,
  approve,
}: {
  appId: string
  requestId: string
  approve?: boolean
}) {
  try {
    return await appInstanceAuth.put('/platform', JSON.stringify({ appId, requestId, approve }))
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function updateAppById(uuid: string, appData: appInterface.IUpdateApp) {
  try {
    return await appInstanceAuth.put(`/id/${uuid}`, JSON.stringify(appData))
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function getSuggestedAppUpdates(
  page?: number,
): Promise<pageInterface.IPage<appSuggestionInterface.IAppSuggestionExtended>> {
  try {
    const { data } = await appInstanceAuth.get('/suggestions', { params: { page } })
    return data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function getSuggestedAppUpdateById(
  uuid: string,
): Promise<appSuggestionInterface.IAppSuggestionExtended> {
  try {
    const { data } = await appInstanceAuth.get(`/suggestions/${uuid}`)
    return data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function deleteSuggestedAppUpdateById(uuid: string) {
  try {
    await appInstanceAuth.delete(`/suggestions/${uuid}`)
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function approveSuggestedAppUpdateById(uuid: string) {
  try {
    await appInstanceAuth.post('/suggestions/approve', { uuid })
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function deletePlatform(uuid: string, platformType: keyof IPlatforms) {
  try {
    await adminInstance.delete('/platform', { data: JSON.stringify({ uuid, platformType }) })
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function getReportedApps(page: number) {
  try {
    const filter = JSON.stringify({ reports: { $exists: true, $ne: [] } })
    const apps = await appInstanceAuth.get('/all', {
      params: {
        page,
        filter,
      },
    })
    return apps.data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}
